import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import useReactRouter from 'use-react-router';
import * as dialogsActions from 'redux/modules/dialogs';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import AuthForm from 'components/AuthForm/Loadable';
import styled from 'styled-components';
import { media } from 'theme/styled-theme';
import { sendEvent } from 'utils/gtag';

const DialogContent = styled(MuiDialogContent)`
  && {
    background-color: white;
    padding: 0.5rem 1.5rem 0.25rem 1.5rem;
    overflow-y: visible!important;

    ${media.forTabletPortraitUp`
      min-width: 443px;
    `}
  }
`;

const LoginDialog = props => {
  const {
    mobileDetect,
    toggleLoginDialog,
    isLoginDialogOpen,
    currentLoginForm
  } = props;

  const { history, location } = useReactRouter();
  const { pathname, search } = location;
  const displayFullScreen = mobileDetect.isMobile && !mobileDetect.isTablet;

  const pathHash = `#${currentLoginForm}-dialog`;

  const handleClose = useCallback(() => {
    toggleLoginDialog();

    // Close modal should return to current parent page
    if (!__SERVER__ && typeof window !== 'undefined') {
      history.replace(`${pathname}${search}`);
      sendEvent({ action: 'Close Login Dialog', category: 'navigation' });
    }
  }, [pathname, search]);

  useEffect(() => {
    if (isLoginDialogOpen) {
      history.replace(`${pathname}${search}${pathHash}`);
    }
  }, [isLoginDialogOpen, pathname, pathHash]);

  useEffect(() => {
    // Hide intercom when dialog is open in Mobile
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: isLoginDialogOpen
      });
    }
  }, [isLoginDialogOpen]);

  return (
    <Dialog
      modal={false}
      open={isLoginDialogOpen}
      fullScreen={displayFullScreen}
    >
      <DialogActions>
        <IconButton onClick={handleClose}>
          <ClearIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <AuthForm
          hideHeader
          handleDialogClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  mobileDetect: PropTypes.objectOf(PropTypes.any),
  isLoginDialogOpen: PropTypes.bool.isRequired,
  toggleLoginDialog: PropTypes.func.isRequired,
  currentLoginForm: PropTypes.string,
};

LoginDialog.defaultProps = {
  mobileDetect: {},
  currentLoginForm: '',
};

export default compose(
  connect(state => ({
    isLoginDialogOpen: state.dialogs?.isLoginDialogOpen,
    currentLoginForm: state.dialogs?.currentLoginForm,
    mobileDetect: state.mobileDetect,
  }), {
    ...dialogsActions,
  }),
)(LoginDialog);
